const SET_SEMESTER_OPTIONS = 'SET_SEMESTER_OPTIONS'
const SET_CAMPUSES_OPTIONS = 'SET_CAMPUSES_OPTIONS'
const SET_TEAMMEMBERS_OPTIONS = 'SET_TEAMMEMBERS_OPTIONS'
const SET_OPTIONS_ARE_LOADING = 'SET_OPTIONS_ARE_LOADING'
const SET_PROGRAM_OPTIONS = 'SET_PROGRAM_OPTIONS';
const SET_ACADEMIC_LEVEL_OPTIONS = 'SET_ACADEMIC_LEVEL_OPTIONS';
const SET_DEPOSITED_OPTIONS = 'SET_DEPOSITED_OPTIONS';
const RESET_INITIAL_STATE_SELECTORS = 'RESET_INITIAL_STATE_SELECTORS';

export {
    SET_SEMESTER_OPTIONS,
    SET_CAMPUSES_OPTIONS,
    SET_TEAMMEMBERS_OPTIONS,
    SET_OPTIONS_ARE_LOADING,
    SET_PROGRAM_OPTIONS,
    RESET_INITIAL_STATE_SELECTORS,
    SET_ACADEMIC_LEVEL_OPTIONS,
    SET_DEPOSITED_OPTIONS, //this is for the deposited filter
}