import domready from 'domready';
import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import store from './store';

domready(() => {
  run();
});

const run = () => {
  const App = lazy(() => import('./App'));

  render(
    <Suspense fallback={null}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>,
    document.getElementById('root')
  );
}