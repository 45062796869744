const SET_ON_THE_BOOKS_FILTER = 'SET_ON_THE_BOOKS_FILTER'
const SET_ON_THE_BOOKS_FILTER_OPTIONS = 'SET_ON_THE_BOOKS_FILTER_OPTIONS'

const SET_ON_THE_BOOKS_LEADS = 'SET_ON_THE_BOOKS_LEADS'
const SET_TODAYS_LEADS = 'SET_TODAYS_LEADS'

const SET_SEMESTER_FILTER_VALUE = 'SET_SEMESTER_FILTER_VALUE'
const SET_SEMESTER_OPTIONS = 'SET_SEMESTER_OPTIONS'

const SET_DEPOSITED_FILTER_VALUE = 'SET_DEPOSITED_FILTER_VALUE' //DEPOSITED FILTER
const SET_DEPOSITED_OPTIONS = 'SET_DEPOSITED_OPTIONS' //DEPOSITED FILTER

const SET_CAMPUS_FILTER_VALUE = 'SET_CAMPUS_FILTER_VALUE'

const SET_TEAM_MEMBERS_FILTER_VALUE = 'SET_TEAM_MEMBERS_FILTER_VALUE'

const SET_LEAD_STATUS_FILTER_VALUE = 'SET_LEAD_STATUS_FILTER_VALUE'
const SET_LEAD_DECISION_FILTER_VALUE = 'SET_LEAD_DECISION_FILTER_VALUE';

const SET_PROGRAM_FILTER_VALUE = 'SET_PROGRAM_FILTER_VALUE'
const SET_ACADEMIC_LEVEL_FILTER_VALUE = 'SET_ACADEMIC_LEVEL_FILTER_VALUE'

const RESET_INITIAL_STATE_FILTER = 'RESET_INITIAL_STATE_FILTER';

const SET_APPLICANT_KEYWORD = 'SET_APPLICANT_KEYWORD';
const SET_FACTOR_RECALCULATE_VALUE = "SET_FACTOR_RECALCULATE_VALUE"

export {
    SET_ON_THE_BOOKS_FILTER,
    SET_ON_THE_BOOKS_FILTER_OPTIONS,
    SET_ON_THE_BOOKS_LEADS,
    SET_TODAYS_LEADS,
    SET_SEMESTER_FILTER_VALUE,
    SET_SEMESTER_OPTIONS,
    SET_CAMPUS_FILTER_VALUE,
    SET_TEAM_MEMBERS_FILTER_VALUE,
    SET_LEAD_STATUS_FILTER_VALUE,
    SET_LEAD_DECISION_FILTER_VALUE,
    SET_PROGRAM_FILTER_VALUE,
    RESET_INITIAL_STATE_FILTER,
    SET_APPLICANT_KEYWORD,
    SET_FACTOR_RECALCULATE_VALUE,
    SET_ACADEMIC_LEVEL_FILTER_VALUE,
    SET_DEPOSITED_FILTER_VALUE,
    SET_DEPOSITED_OPTIONS,
}
