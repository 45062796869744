import { IReduxReducer, ISelectorsReducerState } from 'common/types/redux/IReducer';
import {
    SET_SEMESTER_OPTIONS,
    SET_CAMPUSES_OPTIONS, 
    SET_TEAMMEMBERS_OPTIONS,
    SET_OPTIONS_ARE_LOADING,
    RESET_INITIAL_STATE_SELECTORS, 
    SET_PROGRAM_OPTIONS,
    SET_ACADEMIC_LEVEL_OPTIONS,
    SET_DEPOSITED_OPTIONS, //this is for the deposited filter
} from 'redux/constants/selectorsConstants'

const initialState = {
    semesterOptions: [],
    campusesOptions: [],
    teamMembersOptions: [],
    programOptions: [],
    academicLevelOptions: [],
    depositedOptions: [],
    optionsAreLoading: true,
} as ISelectorsReducerState

const reducer = (state = initialState, { payload, type }: IReduxReducer) => {
    switch (type) {
        case SET_SEMESTER_OPTIONS:
           return {
                ...state,
                semesterOptions: payload,
           }
        case SET_CAMPUSES_OPTIONS:
           return {
                ...state,
                campusesOptions: payload,
           }
        case SET_TEAMMEMBERS_OPTIONS:
           return {
                ...state,
                teamMembersOptions: payload,
           }
        case SET_PROGRAM_OPTIONS:
           return {
                ...state,
                programOptions: payload,
           }
        case SET_DEPOSITED_OPTIONS:
           return {
               ...state,
               depositedOptions: payload,
           }
        case SET_ACADEMIC_LEVEL_OPTIONS:
           return {
                ...state,
                academicLevelOptions: payload,
           }
        case SET_OPTIONS_ARE_LOADING:
            return {
                 ...state,
                 optionsAreLoading: payload,
            }
        case RESET_INITIAL_STATE_SELECTORS:
           return { ...initialState };
        default:
            return state;
    }
};

export default reducer;